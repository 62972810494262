import React, { useEffect, useRef, useState } from 'react';
import "../../Assets/css/home.css";
import { Col, Row, Image, Container, Button } from 'react-bootstrap';

import { BsArrowRightShort } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
// import ReactPlayer from 'react-player'
import { isMobile, isMobileOnly } from 'react-device-detect';
import Slider from "react-slick";
import HmBanner from "../../Assets/images/star-home/hm-banner.png";
import hmMobBanner from "../../Assets/images/star-home/hm-banner-small.png";
import Gab from "../../Assets/images/star-home/Gabriel-Banner.jpg";
import GabMobile from "../../Assets/images/star-home/Gabriel-Banner-mobile.png";

const BannerSlider = () => {
  const history = useHistory();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerPadding: "20px",
    accessibility: true,
    arrows: true,
    // autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: true,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]

  };
  const banner = localStorage.getItem('bw-bannerdata') ? JSON.parse(localStorage.getItem('bw-bannerdata')) : []
  const bannerimg = isMobileOnly ? banner[0]?.mobile_image : banner[0]?.image;


  const videoEl = useRef(null);
  const attemptPlay = () => {
    // setInterval(() => console.log("helloooooo"), 2000);
    setTimeout(() => setLoader(false), 2000);
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const [loader, setLoader] = useState(true);



  return (
    <>

      <div className="rcs_hero_img_d d-none">
        <Row className='m-auto w-100'>
          <Col md={12} className='m-auto p-0'>
            {/* <div className='rcs_home_video_main'>
                  <ReactPlayer url="https://dl2vs6wk2ewna.cloudfront.net/media/adele.mp4" width='100%'
                    height='100%'
                    playing
                    loop
                    muted
                    playsinline
                  />
                </div> */}





          </Col>
        </Row>

        {/* <Slider className="dfl_banner_slider_main" {...settings}>

          <div className='dfl_home_banner_item ' >
            <img src={HmBanner} />
            <div className='dfl_home_banner_text rcs_hero_img' >
              <div>
                <h1 >Fine Jewelry</h1>
                <p>Explore the latest Fine Jewelry</p>
                <Button >Shop Fine Jewelry</Button>
              </div>

            </div>
          </div>

          <div className='dfl_home_banner_item ' >
            <img src={Gab} />
            <div className='dfl_home_banner_text rcs_hero_img' >
              <div>
                <h1>Fine Jewelry</h1>
                <p>Explore the latest Fine Jewelry</p>
                <Button >Shop Fine Jewelry</Button>
              </div>

            </div>
          </div>


        </Slider> */}
      </div>

      <Row className='m-0 w-100 d-none'>

        <Col className=''>
          <Slider className="dfl_banner_slider_main" {...settings}>

            <div className="rcs_hero_slider">
              <div className="rcs_hero_img">

                <div className='text-left  ml-1'>
                  <h4 > Love & Engagement </h4>
                  <h1>Diamond Rings</h1>
                  <p >Shop Our Latest Collection of Diamond Engagement Rings</p>
                  <Button variant="outline-dark" className='sj_border_btn' onClick={() => history.push('/collections/engagement-rings')}>Shop Now <BsArrowRightShort /> </Button>
                </div>
              </div>
            </div>


            <div className="rcs_hero_slider">
              <div className="rcs_hero_img">

                <div className='text-left  ml-1'>
                  <h4 > Love & Engagement </h4>
                  <h1>Diamond Rings</h1>
                  <p >Shop Our Latest Collection of Diamond Engagement Rings</p>
                  <Button variant="outline-dark" className='sj_border_btn' onClick={() => history.push('/collections/engagement-rings')}>Shop Now <BsArrowRightShort /> </Button>
                </div>
              </div>
            </div>

          </Slider>
        </Col>

      </Row>




      <Slider className="rcs_slider_img" {...settings}>

        <div className="rcs_hero_slider banner_img_1">
          {isMobileOnly ?
            <img src={hmMobBanner} /> : <img className='banner_img_1' src={HmBanner} />}

          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col md={9} className='m-auto'>
                  <div className='text-left  ml-1'>
                    <h4 > Love & Engagement </h4>
                    <h1>Diamond Rings</h1>
                    <p >Shop Our Latest Collection of Diamond Engagement Rings</p>
                    <Button variant="outline-dark" className='sj_border_btn' onClick={() => history.push('/collections/engagement-rings')}>Shop Now <BsArrowRightShort /> </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>


        <div className="rcs_hero_slider " >
          <Link to="/fashion">
            {isMobileOnly ?
              <img src={GabMobile} /> : <img className='banner_img_1' src={Gab} />}
          </Link>
        </div>
      </Slider>





    </>
  )
}

export default BannerSlider;

