import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useHistory } from 'react-router-dom';



const RingStyle = () => {
    var history = useHistory();

    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const shopbystyle = (value) => {
        var data1 = {
            style: value,
        }
        sessionStorage.setItem("ad_s_filter", JSON.stringify(data1));
        history.push('/engagement-rings');
        window.scrollTo(0,0);
    }
    return (
        <>
            <Container className='rcs_custom_home_container mt-3' >
                <Row>
                    <Col lg="12" col="12">
                        <div className="rcs_ring_style_title rcs_collection1_title" >
                            <h1> shop Rings by style </h1>
                        </div>
                    </Col>
                </Row>
                <div className='rcs_ringstyle' >
                    <Slider {...settings}>
                        <div className='rcs_ringstyle_content'>
                            <Image src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/solitaire_ring.png" alt='Solitaire' onClick={() => shopbystyle('Solitaire')}></Image>
                            <h2 onClick={() => shopbystyle('Solitaire')}>Solitaire</h2>
                        </div>
                        <div className='rcs_ringstyle_content' >
                            <Image onClick={() => shopbystyle('Pave')} src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/pave_ring.png" alt='Pave'></Image>
                            <h2 onClick={() => shopbystyle('Pave')}>Pave</h2>
                        </div>
                        <div className='rcs_ringstyle_content' >
                            <Image onClick={() => shopbystyle('Halo')} src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/halo_ring.png" alt='Halo'></Image>
                            <h2 onClick={() => shopbystyle('Halo')}>Halo</h2>
                        </div>
                        {/* <div className='rcs_ringstyle_content' >
                            <Image onClick={()=> shopbystyle('Vintage')} src={Vintage} alt='Vintage'></Image>
                            <h2 onClick={()=> shopbystyle('Vintage')}>Vintage</h2>
                        </div> */}
                        <div className='rcs_ringstyle_content' >
                            <Image onClick={() => shopbystyle('Three stone')} src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/three_stone_ring.png" alt='Three Stone'></Image>
                            <h2 onClick={() => shopbystyle('Three stone')}>Three Stone</h2>
                        </div>
                        <div className='rcs_ringstyle_content' >
                            <Image onClick={() => shopbystyle('Channel Set')} src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/single_row_ring.png" alt='Channel Set'></Image>
                            <h2 onClick={() => shopbystyle('Channel Set')}>Channel Set</h2>
                        </div>
                        <div className='rcs_ringstyle_content' >
                            <Image onClick={() => shopbystyle('Multirow')} src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/multirow_ring.png" alt='Multirow'></Image>
                            <h2 onClick={() => shopbystyle('Multirow')}>Multirow</h2>
                        </div>
                        <div className='rcs_ringstyle_content' >
                            <Image onClick={() => shopbystyle('Bypass')} src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/bypass_ring.png" alt='Bypass'></Image>
                            <h2 onClick={() => shopbystyle('Bypass')}>Bypass</h2>
                        </div>
                        <div className='rcs_ringstyle_content' >
                            <Image onClick={() => shopbystyle('Side stone')} src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/side_stone_ring.png" alt='Side-stone'></Image>
                            <h2 onClick={() => shopbystyle('Side stone')}>Side Stone</h2>
                        </div>
                        {/* <div className='rcs_ringstyle_content' >
                            <Image onClick={()=> shopbystyle('Other')} src={other} alt='other'></Image>
                            <h2 onClick={()=> shopbystyle('Other')}>Other</h2>
                        </div> */}
                    </Slider>
                </div>
            </Container>
        </>
    );
}

export default RingStyle;