import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "../src/Assets/css/custome.css";
import "../src/Assets/css/color.css";
import home from "./Containers/Front/Home";
import { base_url, isLogin, postHeader } from "./Helpers/request";
import React, { useLayoutEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionId } from "./Reducer/homeReducer";
import { Skeleton } from "@mui/material";
const Mainrouter = React.lazy(() => import("./mainrouter"));
const GotoTop = React.lazy(() => import("./Containers/Pages/Static/GotoTop"));
const Header = React.lazy(() => import("./Containers/Header/Header"));
const Footer = React.lazy(() => import("./Containers/Footer/footer"));
const App = () => {
  const [dropdown, setDropdown] = useState();

  // if (loading || loadingbanner) {
  //   return <div className="gs_loader1">
  //     <CircularProgress />
  //   </div>
  // }
  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <><Skeltonheader /></>
          }
        >
          <Header />
        </Suspense>
        <Switch>
          <Route exact path="/" component={home} />
          <Suspense
             fallback={
              <><Skelton /></>
            }
          >
            <Route path="*" exact={true} component={Mainrouter} />
          </Suspense>
        </Switch>
        <Suspense
          fallback={
            <><Skelton /></>
          }
        >
          <Footer />
          <GotoTop />
        </Suspense>
      </BrowserRouter>
    </>
  );
};

const Skelton = () => {
  return (<>
    <div className='sk' style={{ margin: "15px 0px" }}>
      <Skeleton variant="text" animation="wave" />
    </div>
  </>)
}

const Skeltonheader = () => {
  return (<>
    <div className='skh' style={{ margin: "0px 0px 10px 0px" }}>
      <Skeleton variant="text" animation="wave" height={75} />
    </div>
  </>)
}

export default App;