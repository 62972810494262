import React, { useEffect, useRef } from 'react';
import { Col, Row, Image, Container, Button } from 'react-bootstrap';
import StartBannerImg from '../../Assets/images/start-banner.jpg'
import { BsArrowRightShort } from 'react-icons/bs';
import Loosediamond from "../../Assets/images/star-home/banner.jpg"
import { NavLink } from 'react-router-dom';

const LooseDiamond = () => {


    return (
        <>
            <section className="sj_loose_diamond_section">
                {/* <img src={Loosediamond} /> */}

                {/* <Container>
                    <Row className='m-auto w-100'>
                        <Col lg={9} md={12} className='m-auto'>
                            <div className='sj_loose_diamond_content'>
                                <h2 >Loose Diamonds</h2>
                                <p >You can love your diamonds and be ethically responsible at the same time. No compromises.
                                    Every diamond in our showroom is sustainably gathered from verified post-consumer sources right here in the
                                    United States. No environmentally devastating mining operations, no human rights abuses.
                                    You can wear your jewelry with pride, knowing it was responsibly made.</p>
                                <Button variant="outline-dark" className='sj_border_btn'> <NavLink to="/diamonds"> Shop Diamonds </NavLink>   </Button>
                            </div>
                        </Col>
                    </Row>
                </Container> */}

                <Row className='m-auto w-100'>
                    <Col lg={6} md={12} className="ad_loose_diamond_banner m-auto">
                        <div className='sj_loose_diamond_content'>
                            <h2>Loose Diamonds</h2>
                            <p >You can love your diamonds and be ethically responsible at the same time. No compromises.
                                Every diamond in our showroom is sustainably gathered from verified post-consumer sources right here in the
                                United States. No environmentally devastating mining operations, no human rights abuses.
                                You can wear your jewelry with pride, knowing it was responsibly made.</p>
                            <Button variant="outline-dark" className='sj_border_btn'> <NavLink to="/diamonds"> Shop Diamonds </NavLink>   </Button>
                        </div>
                    </Col>
                    <Col lg={6} md={12} className="ad_gabriel_diamond_banner m-auto">
                        <div className='sj_loose_diamond_content'>
                            <h2 >Gabriel & Co.</h2>
                            <p >You can love your diamonds and be ethically responsible at the same time. No compromises.
                                Every diamond in our showroom is sustainably gathered from verified post-consumer sources right here in the
                                United States. No environmentally devastating mining operations, no human rights abuses.
                                You can wear your jewelry with pride, knowing it was responsibly made.</p>
                            <Button variant="outline-dark" className='sj_border_btn'> <NavLink to="/bridal"> Shop Now </NavLink>   </Button>
                        </div>
                    </Col>
                </Row>


            </section>

        </>
    )
}

export default LooseDiamond;

