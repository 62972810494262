import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { NavLink, useHistory } from "react-router-dom";
import TitleShape from "../../Assets/images/star-home/title_img.png";

function ShopDiamondShape() {
  var history = useHistory();
  var settings2 = {
    dots: false,
    centerPadding: "60px",
    infinite: true,
    centerMode: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
    autoplay: false,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const shopbyshape = (value) => {
    var data1 = {
      shape: value,
    };
    sessionStorage.setItem("ad_d_filter", JSON.stringify(data1));
    history.push("/diamonds");
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className="rcs_ring_style_section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="rcs_shop_by_diamond_title" data-aos="fade-up">
                <h2>shop diamonds by shape</h2>
              </div>
            </Col>

            <Col lg={12} className="p-0 d-none d-lg-block d-xl-block ">
              <div className="rcs_customer_review_slider" data-aos="fade-up">
                <div className="ad_shopByShape_main">
                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Round")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Diamond_Round.svg"
                            alt="Round"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Round.png"
                            alt="Round"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Round")}> Round </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Princess")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/princess.svg"
                            alt="Princess"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Princess.png"
                            alt="Princess"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Princess")}>
                          Princess  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Cushion")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/cushion.svg"
                            alt="Cushion"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Cushion.png"
                            alt="Cushion"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Cushion")}>
                            
                          Cushion  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Emerald")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/emerald.svg"
                            alt="Emerald"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Emerald.png"
                            alt="Emerald"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Emerald")}>
                            
                          Emerald  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Oval")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/oval.svg"
                            alt="Oval"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Oval.png"
                            alt="Oval"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Oval")}> Oval </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Radiant")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/radiant.svg"
                            alt="Radiant"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Radiant.png"
                            alt="Radiant"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Radiant")}>
                            
                          Radiant  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Asscher")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/asscher.svg"
                            alt="Asscher"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Asscher.png"
                            alt="Asscher"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Asscher")}>
                            
                          Asscher  
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Marquise")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/marquise.svg"
                            alt="Marquise"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Marquise.png"
                            alt="Marquise"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Marquise")}>
                            
                          Marquise  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Heart")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/heart.svg"
                            alt="Heart"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Heart.png"
                            alt="Heart"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Heart")}> Heart </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Pear")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/pear.svg"
                            alt="Pear"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Pear.png"
                            alt="Pear"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Pear")}> Pear </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={12} className="p-0 d-block d-lg-none d-xl-none ">
              <div className="rcs_customer_review_slider">
                <Slider
                  className="rcs_customer_say_inner rcs_shop_diamond_slider"
                  {...settings2}
                >
                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Round")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Diamond_Round.svg"
                            alt="Round"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Round.png"
                            alt="Round"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Round")}> Round </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Princess")}
                    >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/princess.svg"
                            alt="Princess"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Princess.png"
                            alt="Princess"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Princess")}>
                            
                          Princess  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Cushion")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/cushion.svg"
                            alt="Cushion"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Cushion.png"
                            alt="Cushion"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Cushion")}>
                            
                          Cushion  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Emerald")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/emerald.svg"
                            alt="Emerald"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Emerald.png"
                            alt="Emerald"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Emerald")}>
                            
                          Emerald  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Oval")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/oval.svg"
                            alt="Oval"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Oval.png"
                            alt="Oval"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Oval")}> Oval </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Radiant")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/radiant.svg"
                            alt="Radiant"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Radiant.png"
                            alt="Radiant"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Radiant")}>
                            
                          Radiant  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Asscher")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/asscher.svg"
                            alt="Asscher"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Asscher.png"
                            alt="Asscher"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Asscher")}>
                            
                          Asscher  
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Marquise")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/marquise.svg"
                            alt="Marquise"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Marquise.png"
                            alt="Marquise"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Marquise")}>
                            
                          Marquise  
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Heart")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/heart.svg"
                            alt="Heart"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Heart.png"
                            alt="Heart"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Heart")}> Heart </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="rcs_shop_by_diamond_box_main_parent"
                    onClick={() => shopbyshape("Pear")}
                  >
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/pear.svg"
                            alt="Pear"
                          />
                          <Image
                            className="rcs_shop_by_diamond_box_img_hover"
                            src="https://dl2vs6wk2ewna.cloudfront.net/scrap/rothschild/diamond-shape/Pear.png"
                            alt="Pear"
                          />
                        </div>
                        <h3 onClick={() => shopbyshape("Pear")}> Pear </h3>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ShopDiamondShape;
