import React, { useState, useEffect, Suspense } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import ExploreCollection from './ExploreCollection';
import Virtualappointment from './VirtualAppointment';
import ShopDiamondShape from "./ShopDiamondShape";
import RingStyle from "./RingStyle";
import Ringbuilderhome from "./Ringbuilderhome";
import AOS from 'aos';
import 'aos/dist/aos.css' ;
import LooseDiamond from "./LooseDiamond";
import PeopleSay from "./PeopleSay";
const Insta = React.lazy(() => import('./insta'));

const Home = () => {

  useEffect(() => {
    AOS.init({
      duration : 1000
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Online Jewelry Store Virginia | Best Jeweler | Adele diamond</title>
        <meta name="description" content="Adele diamond is different, come see why. We have the largest selection of natural and lab diamonds."></meta>
        <meta name="keywords" content=""></meta>
        {/*Og Tags */}        
      </Helmet>
      <BannerSlider />
      <ExploreCollection />
      <Ringbuilderhome />
      <LooseDiamond/>
      <ShopDiamondShape />  
      <PeopleSay />
      <Suspense fallback={()=>{<></>}}  >  
      <Insta />
      </Suspense>

    </>
  )
}

export default Home;