import React from "react";
import { Container, Row, Col, NavLink, Button, Image } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import PeopleSayImg from "../../Assets/images/star-home/people-img1.webp";
import Rating from "@mui/material/Rating";
import GoogleImg from "../../Assets/images/google.png";
import YelpImg from "../../Assets/images/yelp.png";

const PeopleSay = () => {
  const [value, setValue] = React.useState(5);

  var People = {
    dots: false,
    centerPadding: "60px",
    infinite: true,
    fade: true,
    speed: 500,
    centerMode: true,
    accessibility: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <>
      <section className="sj_whatPeopleSay_section">
        <Container className="rcs_custom_home_container ">
          <Row>
            <Col lg={6} className="offset-lg-1">
              <div
                className="sj_whatPeople_title"
              >
                <h3> What People Say </h3>
              </div>
            </Col>
          </Row>

          <Slider {...People} className="sj_people_say_slider">
            <Col lg={12} className="">
              <div className="sj_people_slider_item">
                <div className="sj_people_slide_content_left">
                  <h3> grady kenner </h3>
                  <span className="sj_people_review_text"> Google Review </span>
                  <p>
                    My experience with Adele surpassed anything I could have
                    ever expected from a Jeweler! I was looking for an
                    engagement ring and wanted to be different and custom. The
                    whole process of design and engagement prep can be
                    intimidating but not with Adeles team. My now Fiancé is in
                    love with her ring and its surely a head turner! I could not
                    be happier with the professionalism, the care, and the
                    expertise Adele carries. I highly recommend!!
                  </p>
                  {/* <NavLink to="/">  Read More  </NavLink> */}
                  <ul>
                    <li>
                      {" "}
                      <Rating name="read-only" value={value} readOnly />{" "}
                      <Image
                        src={GoogleImg}
                        alt="Google Image"
                        className="ad_google_review_img"
                      />
                    </li>
                  </ul>
                </div>

                <div className="sj_people_slide_content_right">
                  <img src={PeopleSayImg} />
                </div>
              </div>
            </Col>

            <Col lg={12} className="">
              <div className="sj_people_slider_item">
                <div className="sj_people_slide_content_left">
                  <h3> Scott Aadal </h3>
                  <span className="sj_people_review_text"> Google Review </span>
                  <div className="sj_review_text">
                    <p>
                      {" "}
                      We had the pleasure of meeting with Ashley and Chris
                      today. What a magnificent experience. It’s a beautiful
                      store, in a safe environment to enjoy your jewelry. For us
                      I was liquidating some old jewelry. They put us in a
                      private room so we could evaluate and come to terms with a
                      reasonable offer. this was not a high-pressure situation,
                      this is a very cordial polite, and respectful let alone
                      professional conversation. I believe they were fair with
                      their offers, And quite frankly I really enjoyed the
                      opportunity to work with them and liquefying our assets.
                      It was evident with all the tests that they had the
                      knowledge, skills, and experience to make us a reasonable
                      offer. This is the way true professionals behave as there
                      were some items that didn’t meet the criteria and I was
                      able to take them off the table. If you’re looking to sell
                      your jewelry, you should strongly consider at least
                      meeting with them. I was very pleased with the end result.
                      I think you would be happy too ! Out of all the jewelry
                      stores in Virginia Beach this was a pleasurable
                      experience. Without the pressure.
                    </p>
                    {/* <NavLink to="/">  Read More  </NavLink> */}
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <Rating name="read-only" value={value} readOnly />{" "}
                      <Image
                        src={GoogleImg}
                        alt="Google Image"
                        className="ad_google_review_img"
                      />
                    </li>
                  </ul>
                </div>

                <div className="sj_people_slide_content_right">
                  <img src={PeopleSayImg} />
                </div>
              </div>
            </Col>

            <Col lg={12} className="">
              <div className="sj_people_slider_item">
                <div className="sj_people_slide_content_left">
                  <h3> Sliqq Bill </h3>
                  <span className="sj_people_review_text"> Google Review </span>
                  <div className="sj_review_text">
                    <p>
                      Jameson was great, he was able to help with everything and
                      was also able to get everything done on quick time crunch.
                      I would recommend Jameson and Adele to anyone looking for
                      an engagement ring and plan for the big day!
                    </p>
                    {/* <NavLink to="/">  Read More  </NavLink> */}
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <Rating name="read-only" value={value} readOnly />{" "}
                      <Image
                        src={GoogleImg}
                        alt="Google Image"
                        className="ad_google_review_img"
                      />
                    </li>
                  </ul>
                </div>

                <div className="sj_people_slide_content_right">
                  <img src={PeopleSayImg} />
                </div>
              </div>
            </Col>

            <Col lg={12} className="">
              <div className="sj_people_slider_item">
                <div className="sj_people_slide_content_left">
                  <h3> Kyle Roark </h3>
                  <span className="sj_people_review_text"> Google Review </span>
                  <div className="sj_review_text">
                    <p>
                      Anya is the best sales representative I've ever met in my
                      life. Hands down. She was super helpful every step of the
                      way and I'm glad she was the one to help me pick out the
                      ring.
                    </p>
                    {/* <NavLink to="/">  Read More  </NavLink> */}
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <Rating name="read-only" value={value} readOnly />{" "}
                      <Image
                        src={GoogleImg}
                        alt="Google Image"
                        className="ad_google_review_img"
                      />
                    </li>
                  </ul>
                </div>

                <div className="sj_people_slide_content_right">
                  <img src={PeopleSayImg} />
                </div>
              </div>
            </Col>
            <Col lg={12} className="">
              <div className="sj_people_slider_item">
                <div className="sj_people_slide_content_left">
                  <h3> Jimmy W. </h3>
                  <span className="sj_people_review_text"> Yelp Review </span>
                  <div className="sj_review_text">
                    <p>
                      Kind, knowledgeable, genuine and considerate people
                      operating boutique shop right here in VB! If every
                      specialty shop could be like Adele, the world would be a
                      better place.
                    </p>
                    {/* <NavLink to="/">  Read More  </NavLink> */}
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <Rating name="read-only" value={value} readOnly />{" "}
                      <Image
                        src={YelpImg}
                        alt="Yelp Image"
                        className="ad_yelp_review_img"
                      />
                    </li>
                  </ul>
                </div>

                <div className="sj_people_slide_content_right">
                  <img src={PeopleSayImg} />
                </div>
              </div>
            </Col>
            <Col lg={12} className="">
              <div className="sj_people_slider_item">
                <div className="sj_people_slide_content_left">
                  <h3>John N. </h3>
                  <span className="sj_people_review_text"> Yelp Review </span>
                  <div className="sj_review_text">
                    <p>
                      Mr Lyons is what business owners should strive to be.
                      Professional, knowledgeable and courteous are just a few
                      of his traits. I bought my first Rolex yesterday after a 4
                      hour drive to his store and he worked diligently to make
                      my transaction as smooth as possible. I plan to make his
                      store a yearly visit to buy future watches. Thanks Chris!
                    </p>
                    {/* <NavLink to="/">  Read More  </NavLink> */}
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <Rating name="read-only" value={value} readOnly />{" "}
                      <Image
                        src={YelpImg}
                        alt="Yelp Image"
                        className="ad_yelp_review_img"
                      />
                    </li>
                  </ul>
                </div>

                <div className="sj_people_slide_content_right">
                  <img src={PeopleSayImg} />
                </div>
              </div>
            </Col>
            <Col lg={12} className="">
              <div className="sj_people_slider_item">
                <div className="sj_people_slide_content_left">
                  <h3>Nicole K. </h3>
                  <span className="sj_people_review_text"> Yelp Review </span>
                  <div className="sj_review_text">
                    <p>
                      The best jewelry store for pressure free shopping. The
                      most amazing staff who are simply phenomenal! An excellent
                      selection of jewelry. I believe they have 1,500 ring
                      styles probably more. Come here to customize your
                      one-of-a-kind ring.
                    </p>
                    {/* <NavLink to="/">  Read More  </NavLink> */}
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <Rating name="read-only" value={value} readOnly />{" "}
                    </li>
                  </ul>
                </div>

                <div className="sj_people_slide_content_right">
                  <img src={PeopleSayImg} />
                </div>
              </div>
            </Col>
            <Col lg={12} className="">
              <div className="sj_people_slider_item">
                <div className="sj_people_slide_content_left">
                  <h3>Keiko M. </h3>
                  <span className="sj_people_review_text"> Yelp Review </span>
                  <div className="sj_review_text">
                    <p>
                      I found their great advertisement and went there right
                      away. It says, ' You can get 3 accessories!' First I
                      thought this is pretty sketchy. Too good to be true. But I
                      was with one of my girlfriends and gave a shot.
                    </p>
                    {/* <NavLink to="/">  Read More  </NavLink> */}
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <Rating name="read-only" value={value} readOnly />{" "}
                      <Image
                        src={YelpImg}
                        alt="Yelp Image"
                        className="ad_yelp_review_img"
                      />
                    </li>
                  </ul>
                </div>

                <div className="sj_people_slide_content_right">
                  <img src={PeopleSayImg} />
                </div>
              </div>
            </Col>
          </Slider>
        </Container>
      </section>
    </>
  );
};
export default PeopleSay;
