import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs'
import Ring1 from '../../Assets/images/star-home/2FSPW0853A.png'
import RingDiamond from '../../Assets/images/star-home/polished-diamond.png'
import VectDiamond from '../../Assets/images/star-home/Vector-diamond.png'
import LineLeft from '../../Assets/images/star-home/line-left.png'
import LineRight from '../../Assets/images/star-home/line-right.png'

const Ringbuilderhome = () => {
    const history = useHistory();
    return (
        <>
            <section className="ad_diamond_ring_section">

                <img className='ad_VectDiamond' src={VectDiamond} />
                <img className='ad_LineLeft' src={LineLeft} />
                {/* <img className='ad_LineRight' src={LineRight} /> */}

                <Container >
                    <Row>
                        <Col lg="12" col="12">
                            <div className="sj_title_main create_title" >
                                <h3 className=''> Create Your Own </h3>
                                <h2> Diamond Ring </h2>
                                <p> Select a setting and choose a diamond to create your
                                  <br/>  own diamond engagement ring.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6}>
                            <div className='ad_diamond_ring_box'>
                                <img src={Ring1} />
                                <h3> Start With A Setting</h3>
                                <p> Browse our assortment of unique engagement
                                    ring settings.</p>
                                <button className='ad_diamond_ring_btn' > <NavLink to="/engagement-rings"> Select Settings  <BsArrowRightShort /> </NavLink>   </button>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className='ad_diamond_ring_box'>
                                <img src={RingDiamond} />
                                <h3> Start With A Diamond</h3>
                                <p> Search through our vast selection of AGS & GIA certified 
                                    diamonds to find your perfect diamond.</p>
                                <button className='ad_diamond_ring_btn'> <NavLink to="/diamonds">  Select Diamond <BsArrowRightShort /> </NavLink> </button>
                            </div>
                        </Col>

                    </Row>


                </Container>
            </section>
        </>
    );
}

export default Ringbuilderhome;